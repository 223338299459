import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, Col, Row,} from "reactstrap";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getUsers} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import ReactExport from "react-export-excel";
import {city} from "../../helpers/status";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Index = props => {
    const dispatch = useDispatch();
    const [skip, setSkip] = useState(0)
    const [type, setType] = useState('all')
    const [limit, setLimit] = useState(100)
    useEffect(() => {
        let item = {
            type: type,
            skip: skip,
            limit: limit,
        }
        dispatch(getUsers(item))
    }, [type])

    const handlePagination = (page) => {

        let item = {
            type: type,
            skip: (page - 1) * limit,
            limit: limit,
        }
        dispatch(getUsers(item))
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="მომხარებლები"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col>
                                            <label className="col-md-2 col-form-label">მომხმარებლები

                                            </label>
                                            <br/>
                                            <div className="button-items">
                                                <Button color="primary"
                                                        onClick={(e) => setType('all')}
                                                        className="btn-soft-primary waves-effect waves-light">ყველა <span
                                                    className="badge bg-soft-danger font-size-12"> {props.users.usersCount}</span></Button>{" "}
                                                <Button color="success"
                                                        onClick={(e) => setType('fiz')}
                                                        className="btn-soft-success waves-effect waves-light">ფიზიკური
                                                    პირი <span
                                                        className="badge bg-soft-danger font-size-12">{props.users.usersFiz}</span></Button>{" "}
                                                <Button color="info"
                                                        onClick={(e) => setType('iur')}
                                                        className="btn-soft-info waves-effect waves-light">იურიდიული
                                                    პირი <span
                                                        className="badge bg-soft-danger font-size-12">{props.users.usersIur}</span></Button>{" "}
                                                <Button color="info"
                                                        onClick={(e) => setType('ind')}
                                                        className="btn-soft-info waves-effect waves-light">ინდ.
                                                    მეწარმე <span
                                                        className="badge bg-soft-danger font-size-12">{props.users.usersIind}</span></Button>{" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>

                                    <ExcelFile
                                        element={<button className='waves-effect waves-light btn btn-primary'>Download
                                            EXEL files</button>}>
                                        <ExcelSheet data={props.users.users} name="Employees">

                                            <ExcelColumn label="AXID" value="AXID"/>
                                            <ExcelColumn label="ელ-ფოსტა" value="email"/>
                                            <ExcelColumn
                                                label="სახელი"
                                                value={(col) => {
                                                    if (parseInt(col?.type) === 1) {
                                                        return `${col.information?.nameKa}`;
                                                    } else if (parseInt(col?.type) === 2) {
                                                        return col.information?.indEntrepreneur
                                                            ? `${col.information?.nameKa}`
                                                            : `${col.information?.LTDName}`;
                                                    } else {
                                                        return ''; // Return a default value if none of the conditions match
                                                    }
                                                }}
                                            />
                                            <ExcelColumn
                                                label="გვარი"
                                                value={(col) => {
                                                    if (parseInt(col?.type) === 1) {
                                                        return `${col.information?.lastNameKa}`;
                                                    } else if (parseInt(col?.type) === 2) {
                                                        return col.information?.indEntrepreneur
                                                            ? `${col.information?.lastNameKa}`
                                                            : null;
                                                    } else {
                                                        return ''; // Return a default value if none of the conditions match
                                                    }
                                                }}
                                            />

                                            <ExcelColumn
                                                label="სახელი En"
                                                value={(col) => {
                                                    if (parseInt(col?.type) === 1) {
                                                        return `${col.information?.nameEn}`;
                                                    } else if (parseInt(col?.type) === 2) {
                                                        return col.information?.indEntrepreneur
                                                            ? `${col.information?.nameEn}`
                                                            : `${col.information?.LTDNameEn}`;
                                                    } else {
                                                        return ''; // Return a default value if none of the conditions match
                                                    }
                                                }}
                                            />
                                            <ExcelColumn
                                                label="გვარი En"
                                                value={(col) => {
                                                    if (parseInt(col?.type) === 1) {
                                                        return `${col.information?.lastNameEn}`;
                                                    } else if (parseInt(col?.type) === 2) {
                                                        return col.information?.indEntrepreneur
                                                            ? `${col.information?.lastNameEn}`
                                                            : null;
                                                    } else {
                                                        return ''; // Return a default value if none of the conditions match
                                                    }
                                                }}
                                            />
                                            <ExcelColumn
                                                label="პირადობის ნომერი / ID"
                                                value={(col) => {
                                                    if (parseInt(col?.type) === 1) {
                                                        return col.information?.passportId || '';
                                                    } else if (parseInt(col?.type) === 2) {
                                                        return col.information?.indEntrepreneur
                                                            ? col.information?.passportId
                                                            : col.information?.orgId;
                                                    } else {
                                                        return ''; // Default value if none of the conditions match
                                                    }
                                                }}
                                            />
                                            <ExcelColumn label="ტელეფონი" value="phone"/>
                                        </ExcelSheet>
                                    </ExcelFile>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                // onRowSelect={onRowSelect}
                                // onSelectAll={onSelectAll}
                                page='index'
                                pagination={handlePagination}
                                usersArray={props.users.users}
                                usersCount={props.users.usersCount}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        users,
    } = state
    return {
        users
    }
};
export default withRouter(connect(mapStateToProps)(Index));
