import {
    GET_LOGISTIC_PRODUCTS_WAITING_REQUEST_SUCCESS,
    GET_LOGISTIC_PRODUCT_REQUEST_SUCCESS,
    GET_SENT_FLIGHT_REQUEST_SUCCESS,
    UPDATE_PRODUCT_REQUEST_SUCCESS,
    DELETE_PRODUCT_REQUEST_SUCCESS,
    UPDATE_CHANGE_STATUS_STEP_1_SUCCESS,
    SEARCH_PRODUCT_REQUEST_SUCCESS,
    DELETE_CHECKED_PRODUCTS_REQUEST_SUCCESS,
    NULL_OBJECT_REQUEST,
    GET_GIVEAWAY_REQUEST_SUCCESS,
    NULL_MODAL_PRODUCT_REQUEST,
    GET_DEPT_SUM_PRODUCT_REQUEST_SUCCESS,
    GET_SENT_FLIGHT_ID_REQUEST,
    GET_FLIGHTS_PRODUCTS, GET_FLIGHTS_PRODUCTS_SUCCESS, GET_ALL_LOGISTIC_PRODUCTS_REQUEST
} from "./actionTypes"
import {CREATE_PRINT_REQUEST_SUCCESS} from "../print/actionTypes";

const INIT_STATE = {



    productsUserCreaterCount: 0,
    productsAdminCreaterCount: 0,
    productsErrors: [],
    products: [],
    productsCount: 0,
    sentFlight: [],
    preloader: true,
    product: {},
    tbilisi: 0,
    kutaisi: 0,

    giveawayFlight: [],
    giveawayFlight3: [],
    giveawayProducts: [],
    giveawayProductsCount: 0,
    giveawayDeptSum: 0,
    giveawayBalance: 0,
    listPreloader: true,

    isLoading: true,
    error: false,


}

const reducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NULL_MODAL_PRODUCT_REQUEST:
            return {
                ...state,
                product: {},
            }

        case GET_FLIGHTS_PRODUCTS:
            return {
                ...state,
                isLoading:true
            }

        case GET_FLIGHTS_PRODUCTS_SUCCESS:
            return {
                ...state,
                isLoading:false,
                products: action.callBack.product,
                productsCount: action.callBack.productCount,
                productsUserCreaterCount: action.callBack.productUserAddCount,
                productsAdminCreaterCount: action.callBack.productAdminAddCount,
            }

        case GET_DEPT_SUM_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                giveawayDeptSum: action.callBack.deptSum,
                giveawayBalance: action.callBack.balance
            }

        case NULL_OBJECT_REQUEST:
            return {
                ...state,
                product: {},
                products: [],
                productsCount: 0,
                giveawayFlight: [],
                giveawayProducts: [],
                giveawayProductsCount: 0
            }
        case GET_GIVEAWAY_REQUEST_SUCCESS:
            return {
                ...state,
                giveawayFlight: action.callBack.newArr,
                giveawayFlight3: action.callBack.newArr3,
                giveawayProducts: action.callBack.products,
                giveawayProductsCount: action.callBack.productCount
            }
        case GET_SENT_FLIGHT_ID_REQUEST:
            return {
                ...state,
                isLoading: true,
                products: [],
                productsCount: 0,
                giveawayFlight: [],
                giveawayProducts: [],
                giveawayProductsCount: 0
            }
        case GET_ALL_LOGISTIC_PRODUCTS_REQUEST:
            return {
                ...state,
                isLoading: true,
                products: [],
                productsCount: 0,
                giveawayFlight: [],
                giveawayProducts: [],
                giveawayProductsCount: 0
            }


        case GET_LOGISTIC_PRODUCTS_WAITING_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                listPreloader: false,
                products: action.callBack.product,
                productsCount: action.callBack.productCount,
                productsUserCreaterCount: action.callBack.productUserAddCount,
                productsAdminCreaterCount: action.callBack.productAdminAddCount,
            }

        case GET_LOGISTIC_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                product: action.callBack.product,
            }

        case CREATE_PRINT_REQUEST_SUCCESS:
            state.giveawayFlight3[action.payload.key].printStatus = true
            return {
                ...state,
            }

        case GET_SENT_FLIGHT_REQUEST_SUCCESS:
            return {
                ...state,
                sentFlight: action.callBack.flights,
            }

        case UPDATE_PRODUCT_REQUEST_SUCCESS:

            const itemToUpdateIndex = state.products.findIndex(item => item._id === action.payloading.callBack.productOne._id);

            if (itemToUpdateIndex !== -1) {
                // Create a new array with the updated item
                const updatedProducts = [...state.products];
                updatedProducts[itemToUpdateIndex] = {
                    ...updatedProducts[itemToUpdateIndex],
                    productOrderId: action.payloading.callBack.productOne.productOrderId,
                    custom: action.payloading.callBack.productOne.custom,
                    'userInformation.AXID': action.payloading.callBack.user.AXID,
                    'userInformation.phone': action.payloading.callBack.user.phone,
                    'userInformation.nameEn': action.payloading.callBack.user.nameEn,
                    quantity: action.payloading.callBack.productOne.quantity,
                    productName: action.payloading.callBack.productOne.productName,
                    status: action.payloading.callBack.productOne.status,
                    shop: action.payloading.callBack.productOne.shop,
                    price: action.payloading.callBack.productOne.price,
                    currency: action.payloading.callBack.productOne.currency,
                    weight: action.payloading.callBack.productOne.weight,
                    flightId: action.payloading.callBack.productOne.flightId,

                    'userInformation2.type': action.payloading.callBack.user.type,
                    'userInformation2.phone': action.payloading.callBack.user.phone,
                    'userInformation2.information.nameEn': action.payloading.callBack.user.information.nameEn,
                    'userInformation2.information.lastNameEn': action.payloading.callBack.user.information.lastNameEn,
                    'userInformation2.information.passportId': action.payloading.callBack.user.information.passportId,
                    'userInformation2.information.orgId': action.payloading.callBack.user.information.orgId,
                    'userInformation2.information.LTDNameEn': action.payloading.callBack.user.information.LTDNameEn,
                };

                // Dispatch updateItem action with the updated item
                return {
                    ...state,
                    products: updatedProducts
                };
            }
            return state;



   /*

            */



          /*  if (action.payloading.callBack.customArray.length > 1) {
                for (let a = 0; a < action.payloading.callBack.customArray.length; a++) {
                    state.products.map((item, key) => {
                        if (action.payloading.callBack.customArray[a]._id === item._id) {
                            state.products[key].custom = action.payloading.callBack.custom
                        }
                    })
                }
            }

            state.products[action.payloading.payload.key].custom = action.payloading.callBack.custom
            //state.products[action.payloading.payload.key] = action.payloading.payload.product
            state.products[action.payloading.payload.key].userInformation.AXID = action.payloading.callBack.user.AXID

            state.products[action.payloading.payload.key].quantity = action.payloading.payload.product.quantity
            state.products[action.payloading.payload.key].productName = action.payloading.payload.product.productName
            state.products[action.payloading.payload.key].status = action.payloading.payload.product.status
            state.products[action.payloading.payload.key].shop = action.payloading.payload.product.shop
            state.products[action.payloading.payload.key].price = action.payloading.payload.product.price
            state.products[action.payloading.payload.key].currency = action.payloading.payload.product.currency
            state.products[action.payloading.payload.key].weight = action.payloading.payload.product.weight
            state.products[action.payloading.payload.key].flightId = action.payloading.payload.product.flightId
            state.products[action.payloading.payload.key].userInformation.phone = action.payloading.callBack.user.phone
            state.products[action.payloading.payload.key].userInformation.nameEn = '1111'


            state.products[action.payloading.payload.key].userInformation2.type = action.payloading.callBack.user.type
            state.products[action.payloading.payload.key].userInformation2.information.nameEn = 'tr'
            state.products[action.payloading.payload.key].userInformation2.phone = action.payloading.callBack.user.phone
            state.products[action.payloading.payload.key].userInformation2.information.nameEn = action.payloading.callBack.user.information.nameEn
            state.products[action.payloading.payload.key].userInformation2.information.lastNameEn = action.payloading.callBack.user.information.lastNameEn
            state.products[action.payloading.payload.key].userInformation2.information.passportId = action.payloading.callBack.user.information.passportId
            state.products[action.payloading.payload.key].userInformation2.information.orgId = action.payloading.callBack.user.information.orgId
            state.products[action.payloading.payload.key].userInformation2.information.LTDNameEn = action.payloading.callBack.user.information.LTDNameEn


*/

            return {
                ...state,
            }
        case DELETE_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                products: state.products.filter(todo => todo._id !== action.payload.id)
            }
        case DELETE_CHECKED_PRODUCTS_REQUEST_SUCCESS:
            let result = [];
            state.products.forEach(function (v) {
                if (action.payload.ids.indexOf(v._id) === -1) {
                    result.push(v);
                }
            });
            state.products = result
            return {
                ...state,
            }

        case UPDATE_CHANGE_STATUS_STEP_1_SUCCESS:
            return {
                ...state,
                productsErrors: action.callBack.errorArray,
                productsCount: action.callBack.results,
            }

        case SEARCH_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                products: action.callBack.product,
            }

        default:
            return state
    }
}

export default reducer
