import {call, put, takeEvery} from "redux-saga/effects";
import {
    GET_ALL_LOGISTIC_PRODUCTS_REQUEST,
    GET_LOGISTIC_PRODUCT_REQUEST,
    GET_LOGISTIC_PRODUCTS_WAITING_REQUEST_SUCCESS,
    GET_LOGISTIC_PRODUCT_REQUEST_SUCCESS,
    GET_SENT_FLIGHT_REQUEST,
    GET_SENT_FLIGHT_REQUEST_SUCCESS,
    GET_SENT_FLIGHT_ID_REQUEST,
    GET_SENT_FLIGHT_ID_OBTAINS_REQUEST,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_REQUEST_SUCCESS,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_REQUEST_SUCCESS,
    GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_REQUEST,
    SEARCH_PRODUCT_REQUEST,
    SEARCH_PRODUCT_REQUEST_SUCCESS,
    DELETE_CHECKED_PRODUCTS_REQUEST,
    DELETE_CHECKED_PRODUCTS_REQUEST_SUCCESS,
    GET_GIVEAWAY_REQUEST,
    GET_GIVEAWAY_REQUEST_SUCCESS,
    GET_DEPT_SUM_PRODUCT_REQUEST,
    GET_DEPT_SUM_PRODUCT_REQUEST_SUCCESS,
    UPDATE_PRODUCT_SORTING_REQUEST,
    GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_NEW_REQUEST,
    GET_SENT_FLIGHT_ID_REQUEST_SUCCESS_2, GET_FLIGHTS_PRODUCTS_SUCCESS, GET_FLIGHTS_PRODUCTS
} from "./actionTypes";
import {getAllInformation} from "../information/actions";
import {notification} from "antd";

const modalSuccess = type => {
    notification[type]({
        message: 'წარმატება',
        description: 'ოპერაცია წარმატებით შესრულდა',
    });
};

const modalError = type => {
    notification[type]({
        message: 'შეცდომა!!',
        description: 'არასწორი თრექინგ იდ-ია',
    });
};

const modalError2 = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მოხდა შეცდომა მიმართეთ IT განყოფილებას',
    });
};


function* getLogisticProductByTrackingIdSaga({payload}) {
    try {
        const callBack = yield call(getLogisticProductByTrackingIdSagaAsync, payload)
        if (callBack.result === true) {
            yield put({type: GET_LOGISTIC_PRODUCT_REQUEST_SUCCESS, callBack})
        }else {
            modalError('error');
        }

    } catch (e) {
        console.error(e);
    }
}

const getLogisticProductByTrackingIdSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/getByTrackingId/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getLogisticProductByTrackingIdNewSaga({payload}) {
    try {
        const callBack = yield call(getLogisticProductByTrackingIdNewSagaAsync, payload)
        if (callBack.result === true) {
            yield put({type: GET_LOGISTIC_PRODUCT_REQUEST_SUCCESS, callBack})
        }else {
            modalError('error');
        }

    } catch (e) {
        console.error(e);
    }
}

const getLogisticProductByTrackingIdNewSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/getByTrackingIdNew/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getLogisticProductSaga({payload}) {
    try {
        const callBack = yield call(getLogisticProductSagaAsync, payload)
        yield put({type: GET_LOGISTIC_PRODUCT_REQUEST_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}

const getLogisticProductSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/get/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getAllLogisticProductsSaga({payload}) {
    try {
        const callBack = yield call(getLogisticProductsSagaAsync, payload)
        yield put({type: GET_LOGISTIC_PRODUCTS_WAITING_REQUEST_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}


const getLogisticProductsSagaAsync = async (queryParams) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/gets?${queryParams}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


//getSentFlightSaga
function* getSentFlightSaga({payload}) {
    try {
        const callBack = yield call(getSentFlightSagaAsync,payload)
        yield put({type: GET_SENT_FLIGHT_REQUEST_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}

const getSentFlightSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/flight/getsentflights/${item.status}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getSentFlightIdsSaga({payload}) {
    try {
        const callBack = yield call(getSentFlightIdsSagaAsync, payload)
        yield put({type: GET_LOGISTIC_PRODUCTS_WAITING_REQUEST_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}

const getSentFlightIdsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/getProductsByFlightsId/${item.id}/${item.status}/${item.location}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getFlightsProductsSaga({payload}) {
    try {
        const callBack = yield call(getFlightsProductsSagaAsync, payload)
        yield put({type: GET_FLIGHTS_PRODUCTS_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}

const getFlightsProductsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/flight/getProductsByFlightsId/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* getSentFlightIdsObtainsSaga({payload}) {
    try {
        const callBack = yield call(getSentFlightIdsObtainsSagaAsync, payload)
        yield put({type: GET_LOGISTIC_PRODUCTS_WAITING_REQUEST_SUCCESS, callBack})
    } catch (e) {
        console.error(e);
    }
}

const getSentFlightIdsObtainsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/getProductsByFlightsIdOBtain/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}

function* updateProductSaga({payload}) {
    try {
        const callBack = yield call(updateProductSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess('success');
            let payloading = {
                payload,
                callBack
            }
            yield put({type: UPDATE_PRODUCT_REQUEST_SUCCESS, payloading})
            yield put(getAllInformation())
        }else{
            modalError2('error');
        }

    } catch (e) {
        console.error(e);
    }
}

const updateProductSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/updateProduct`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* updateProductSortingSaga({payload}) {
    try {
        const callBack = yield call(updateProductSortingSagaAsync, payload)
        if (callBack.result === true) {
            modalSuccess('success');
            let payloading = {
                payload,
                callBack
            }
            yield put({type: UPDATE_PRODUCT_REQUEST_SUCCESS, payloading})
            yield put(getAllInformation())
        }else{
            modalError2('error');
        }

    } catch (e) {
        console.error(e);
    }
}

const updateProductSortingSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/updateProductSorting`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* deleteProductSaga({payload}) {
    try {
        const callBack = yield call(deleteProductSagaAsync, payload)
        if (callBack.result === true) {
            yield put({type: DELETE_PRODUCT_REQUEST_SUCCESS, payload})
            yield put(getAllInformation())
        }

    } catch (e) {
        console.error(e);
    }
}

const deleteProductSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/delete`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* searchProductSaga({payload}) {
    try {
        const callBack = yield call(searchProductSagaAsync, payload)
        if (callBack.result === true) {
            yield put({type: SEARCH_PRODUCT_REQUEST_SUCCESS, callBack})
        }else{
            modalError2('error');
        }

    } catch (e) {
        console.error(e);
    }
}

const searchProductSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/getTrakingId/${item.id}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* deleteCheckedProductsSaga({payload}) {
    try {
        const callBack = yield call(deleteCheckedProductsSagaAsync, payload)
        if (callBack.result === true) {
           yield put({type: DELETE_CHECKED_PRODUCTS_REQUEST_SUCCESS, payload})
            yield put(getAllInformation())
        }

    } catch (e) {
        console.error(e);
    }
}

const deleteCheckedProductsSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/deletes`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(item)
    })
    return await response.json()
}

function* getGiveawaySaga({payload}) {
    try {
        const callBack = yield call(getGiveawaySagaAsync, payload)
        if (callBack.result === true) {
           yield put({type: GET_GIVEAWAY_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const getGiveawaySagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/giveaway/${item.barCode}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* geDebtSumSaga({payload}) {
    try {
        const callBack = yield call(geDebtSumSagaAsync, payload)
        if (callBack.result === true) {
            yield put({type: GET_DEPT_SUM_PRODUCT_REQUEST_SUCCESS, callBack})
        }
    } catch (e) {
        console.error(e);
    }
}

const geDebtSumSagaAsync = async (item) => {
    let jwt = localStorage.getItem("token")
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api2/product/debtSum/${item.barCode}`, {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'Authorization': `Bearer ${jwt}`,
        }
    })
    return await response.json()
}


function* productsSaga() {
    yield takeEvery(GET_LOGISTIC_PRODUCT_REQUEST, getLogisticProductSaga)
    yield takeEvery(GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_REQUEST, getLogisticProductByTrackingIdSaga)
    yield takeEvery(GET_LOGISTIC_PRODUCT_BY_TRACKING_ID_NEW_REQUEST, getLogisticProductByTrackingIdNewSaga)
    yield takeEvery(GET_ALL_LOGISTIC_PRODUCTS_REQUEST, getAllLogisticProductsSaga)
    yield takeEvery(GET_SENT_FLIGHT_REQUEST, getSentFlightSaga)
    yield takeEvery(GET_SENT_FLIGHT_ID_REQUEST, getSentFlightIdsSaga)
    yield takeEvery(GET_SENT_FLIGHT_ID_OBTAINS_REQUEST, getSentFlightIdsObtainsSaga)
    yield takeEvery(UPDATE_PRODUCT_SORTING_REQUEST, updateProductSortingSaga)
    yield takeEvery(UPDATE_PRODUCT_REQUEST, updateProductSaga)
    yield takeEvery(DELETE_PRODUCT_REQUEST, deleteProductSaga)
    yield takeEvery(SEARCH_PRODUCT_REQUEST, searchProductSaga)
    yield takeEvery(DELETE_CHECKED_PRODUCTS_REQUEST, deleteCheckedProductsSaga)
    yield takeEvery(GET_GIVEAWAY_REQUEST, getGiveawaySaga)
    yield takeEvery(GET_DEPT_SUM_PRODUCT_REQUEST, geDebtSumSaga)

    yield takeEvery(GET_FLIGHTS_PRODUCTS, getFlightsProductsSaga)
}

export default productsSaga
