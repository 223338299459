import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, Col, Row} from "reactstrap";
import {notification} from 'antd';


import ClipLoader from "react-spinners/ClipLoader";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getAllLogisticProducts,  changeStatusStep5Cheked} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";



const modalWarning = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მონიშნეთ გრაფები',
    });
};
const Arrived = props => {
    const dispatch = useDispatch();


    const isLoading = useSelector((state) => state.products.isLoading);
    const error = useSelector((state) => state.products.error);

    const [sq1, setsq1] = useState(false)
    const [branch, setBranch] = useState('all')
    const [country, setCountry] = useState(1)
    const [creater, setCreater] = useState('all')
    const [uniqArray, setUniqArray] = useState([])
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(3000)
    const [selectProducts, setSelectProducts] = useState([])
    useEffect(() => {


    }, [])


    function onRowSelect(row, isSelected) {

        if (isSelected) {
            setSelectProducts(prevArray =>
                [...prevArray, row._id]
            )
        } else {
            setSelectProducts(selectProducts.filter(item => item !== row._id))
        }
    }



    useEffect(()=>{
        const filters = {
            skip: skip.toString(),
            limit: limit.toString(),
            country: country,
            status: 'arrived',
            creater: creater,
            branch:branch,
        };
        const queryParams = new URLSearchParams(filters).toString();
        dispatch(getAllLogisticProducts(queryParams))

    },[branch,country])

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="ჩამოსული"/>
                    <Row>


                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <label className="col-md-2 col-form-label">ქვეყანა</label>
                                            <select
                                                value={country}
                                                onChange={(e)=>setCountry(e.target.value)}
                                                className="form-control">
                                                <option value={0}>აირჩიეთ ქვეყანა</option>
                                                <option value={1}>ჩინეთი</option>
                                                <option value={2}>ამერიკა</option>
                                                <option value={3}>თურქეთი</option>
                                            </select>
                                            <br/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="col-md-2 col-form-label">ფილიალი</label>
                                            <select
                                                value={branch}
                                                onChange={(e)=>setBranch(e.target.value)}
                                                className="form-control">
                                                <option value={'all'}>ყველა</option>
                                                <option value={'didube'}>დიდუბე</option>
                                                <option value={'gldani'}>გლდანი</option>
                                                <option value={'isani'}>ისანი</option>
                                            </select>
                                            <br/>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {
                                isLoading ? (
                                    <Preloader/>
                                ) : error ? (
                                    <Error/>
                                ) : (
                                    <Row>
                                        <ObjectColumns
                                            onRowSelect={onRowSelect}
                                            logisticProducts={props.products.products}
                                        />
                                    </Row>
                                )
                            }


                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        products,
        logistic
    } = state
    return {
        products,
        logistic
    }
};
export default withRouter(connect(mapStateToProps)(Arrived));
