import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Modal, Row, Table} from "reactstrap";

import Switch from "react-switch"
import {notification} from 'antd';


import ClipLoader from "react-spinners/ClipLoader";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    getAllLogisticProducts,
    changeStatusAll,
    changeStatusStep2Cheked,
    deleteProduct,
    deleteCheckedProducts
} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";
import SweetAlert from "react-bootstrap-sweetalert";
import Preloader from "../../components/Preloader/Preloader";
import Error from "../../components/Error/Error";


const modalWarning = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მონიშნეთ გრაფები',
    });
};
const Waiting = props => {
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.products.isLoading);
    const error = useSelector((state) => state.products.error);

    const [confirm_alert, setconfirm_alert] = useState(false)
    const [sq1, setsq1] = useState(true)
    const [country, setCountry] = useState(1)
    const [creater, setCreater] = useState('all')
    const [uniqArray, setUniqArray] = useState([])
    const [deleteUniqArray, setDeleteUniqArray] = useState([])
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(1000)

    const [selectProducts, setSelectProducts] = useState([])

    useEffect(() => {
        let item = {
            country: country,
            status: 'waiting',
            creater: creater,
            skip: skip,
            limit: limit,
        }

        const filters = {
            skip: skip.toString(),
            limit: limit.toString(),
            country: country,
            status: 'waiting',
            creater: creater,
            branch:'all',
        };

        const queryParams = new URLSearchParams(filters).toString();
        dispatch(getAllLogisticProducts(queryParams))
    }, [])

    const onSelectAll = (isSelected) => {
        if (isSelected) {
            props.products.products.map((item) => {
                setSelectProducts(prevArray =>
                    [...prevArray, item._id]
                )
            })
        } else {
            setSelectProducts([])
        }
    }

    function onRowSelect(row, isSelected) {
        if (isSelected) {
            setSelectProducts(prevArray =>
                [...prevArray, row._id]
            )
        } else {
            setSelectProducts(selectProducts.filter(item => item !== row._id))
        }
    }

    const handleChangeStatusChecked = () => {
        let uniqueSet = new Set(selectProducts)
        let uniArray = [...uniqueSet]
        setUniqArray(uniqArray)
        if (uniArray.length === 0) {
            modalWarning('warning');
        } else {
            let item = {
                sendMessage: sq1,
                ids: uniArray,
                country: country,
                status: 'waiting',
                creater: creater,
                skip: skip,
                limit: limit,
            }
            setSelectProducts([])
            dispatch(changeStatusStep2Cheked(item))
        }
    }
    const handleChangeStatusAll = () => {
        let item = {
            sendMessage: sq1,
            country: country,
            status: 'waiting',
            creater: creater,
            skip: skip,
            limit: limit,
        }
        dispatch(changeStatusAll(item))
    }


    const handleChangeCountry = (i) => {
        let country = i.target.value
        if (country !== 0)
            setCountry(country)
        let item = {
            country: country,
            status: 'waiting',
            creater: creater,
            skip: skip,
            limit: limit,
        }

        const filters = {
            skip: skip.toString(),
            limit: limit.toString(),
            country: country,
            status: 'waiting',
            creater: creater,
            branch:'all',
        };
        const queryParams = new URLSearchParams(filters).toString();
        dispatch(getAllLogisticProducts(queryParams))
    }
    const handleChangeCreator = (i) => {
        setCreater(i)
        let item = {
            country: country,
            status: 'waiting',
            creater: i,
            skip: skip,
            limit: limit,
        }
        const filters = {
            skip: skip.toString(),
            limit: limit.toString(),
            country: country,
            status: 'waiting',
            creater:i,
            branch:'all',
        };
        const queryParams = new URLSearchParams(filters).toString();

        dispatch(getAllLogisticProducts(queryParams))
    }

    const handleRemove = () => {
        let item = {
            ids: deleteUniqArray,
        }
        dispatch(deleteCheckedProducts(item))
        setconfirm_alert(false)
    }

    const handleCheckedRemoved = () => {
        let uniqueSet = new Set(selectProducts)
        let uniArray = [...uniqueSet]
        setDeleteUniqArray(uniArray)
        setUniqArray(uniqArray)
        if (uniArray.length >= 1) {
            setconfirm_alert(true)
        }

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="მოლოდინის რეჟიმში"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>
                                    <div className="button-items">
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn btn-primary w-xs waves-effect waves-light"
                                            disabled={props.logistic.preloaderAll}
                                            onClick={handleChangeStatusAll}
                                        >
                                            {
                                                props.logistic.preloaderAll === true
                                                    ? <ClipLoader color={'#ffffff'} loading={true} size={10}/>
                                                    : null
                                            }

                                            <span>  მისულია საწყობში (ყველა)</span>
                                        </Button>{" "}
                                        <Button

                                            type="button"
                                            color="success"
                                            className="btn btn-success w-lg waves-effect waves-light"
                                            disabled={props.logistic.preloaderChecked}
                                            onClick={handleChangeStatusChecked}

                                        >
                                            {
                                                props.logistic.preloaderChecked === true
                                                    ? <ClipLoader color={'#ffffff'} loading={true} size={10}/>
                                                    : null
                                            }
                                            <span>  მისულია საწყობში (მხოლოდ მონიშნული)</span>
                                        </Button>
                                        <Button
                                            type="button"
                                            color="warning"
                                            className="btn btn-success w-lg waves-effect waves-light"
                                            onClick={handleCheckedRemoved}
                                        >
                                            <span>  მონიშნულის წაშლა</span>
                                        </Button>
                                        <div style={{marginLeft: '8px'}}>
                                            <div className="square-switch">
                                                <input
                                                    type="checkbox"
                                                    id="square-switch1"
                                                    switch="none"
                                                    checked={sq1}
                                                    onChange={() => {
                                                        setsq1(!sq1)
                                                    }}
                                                />
                                                <label
                                                    htmlFor="square-switch1"
                                                    data-on-label="On"
                                                    data-off-label="Off"
                                                />
                                                <span
                                                    style={{position: 'relative', bottom: '16px', marginLeft: '10px'}}
                                                >სმესების და მაილების დაგზავნა </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col>
                                            <hr/>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <label className="col-md-2 col-form-label">ქვეყანა</label>
                                            <select
                                                value={country}
                                                onChange={handleChangeCountry}
                                                className="form-control">
                                                <option value={0}>აირჩიეთ ქვეყანა</option>
                                                <option value={1}>ჩინეთი</option>
                                                <option value={2}>ამერიკა</option>
                                                <option value={3}>თურქეთი</option>
                                            </select>
                                            <br/>
                                            <div className="button-items">
                                                <Button color="primary"
                                                        onClick={handleChangeCreator.bind(this, 'all')}
                                                        className="btn-soft-primary waves-effect waves-light">ყველა <span
                                                    className="badge bg-soft-danger font-size-12">{props.products.waitingCount}</span></Button>{" "}
                                                <Button color="success"
                                                        onClick={handleChangeCreator.bind(this, 'user')}
                                                        className="btn-soft-success waves-effect waves-light">მომხმარებლის
                                                    დამატებული <span
                                                        className="badge bg-soft-danger font-size-12">{props.products.productsUserCreaterCount}</span></Button>{" "}
                                                <Button color="info"
                                                        onClick={handleChangeCreator.bind(this, 'admin')}
                                                        className="btn-soft-info waves-effect waves-light">ადმინისტრატორის
                                                    დამატებული <span
                                                        className="badge bg-soft-danger font-size-12">{props.products.productsAdminCreaterCount}</span></Button>{" "}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {
                                isLoading ? (
                                    <Preloader/>
                                ) : error ? (
                                    <Error/>
                                ) : (
                                    <Row>
                                        <ObjectColumns
                                            onRowSelect={onRowSelect}
                                            onSelectAll={onSelectAll}
                                            logisticProducts={props.products.products}
                                        />
                                    </Row>
                                )
                            }

                        </Col>
                    </Row>
                    <Col xl={3} lg={4} sm={6} className="mb-2">
                        {confirm_alert ? (
                            <SweetAlert
                                title="დარწმუნებული ხართ?"
                                warning
                                showCancel
                                confirmButtonText="Yes, delete it!"
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={handleRemove}
                                onCancel={() => setconfirm_alert(false)}
                            >
                                რომ გსურთ წაშალოთ?!
                            </SweetAlert>
                        ) : null}
                    </Col>
                </div>
            </div>


        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        products,
        logistic
    } = state
    return {
        products,
        logistic
    }
};
export default withRouter(connect(mapStateToProps)(Waiting));
