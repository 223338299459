import React from "react";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import maintenance from "../../assets/images/maintenance.png";

const Error = () => {
    return (
        <React.Fragment>
            <Row>
                <div className="col-12 text-center">
                    <div className="home-wrapper">

                        <Row className="justify-content-center">
                            <Col lg={4} sm={5}>
                                <div className="maintenance-img">
                                    <img src={maintenance} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>
                        </Row>
                        <h3 className="mt-5">Oops! Რაღაც არასწორად წავიდა</h3>
                        <p>გთხოვთ გადატვირთოთ გვერდი ან დაუკავშირდეთ ადმინისტრატორს</p>

                    </div>
                </div>
            </Row>
        </React.Fragment>
    )
}

export default Error
