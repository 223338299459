import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Card, CardBody, CardTitle, Col, Input, Label, Modal, Row, Table} from "reactstrap";

import Switch from "react-switch"
import {notification} from 'antd';


import ClipLoader from "react-spinners/ClipLoader";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {getAllLogisticProducts, changeStatusAll, changeStatusStep2Cheked} from "../../store/actions";

import ObjectColumns from "./ObjectColumns";


const modalWarning = type => {
    notification[type]({
        message: 'შეცდომა',
        description: 'მონიშნეთ გრაფები',
    });
};
const Waiting = props => {
    const dispatch = useDispatch();
    const [branch, setBranch] = useState('all')
    const [country, setCountry] = useState(1)
    const [creater, setCreater] = useState('all')
    const [uniqArray, setUniqArray] = useState([])
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(500)

    const [selectProducts, setSelectProducts] = useState([])

    useEffect(() => {
        const filters = {
            skip: skip.toString(),
            limit: limit.toString(),
            country: country,
            status: 'obscure',
            creater: creater,
            branch
        };
        const queryParams = new URLSearchParams(filters).toString();
        dispatch(getAllLogisticProducts(queryParams))

    }, [country])


    const onSelectAll = (isSelected) => {
        if (isSelected) {
            props.products.products.map((item) => {
                setSelectProducts(prevArray =>
                    [...prevArray, item._id]
                )
            })
        } else {
            setSelectProducts([])
        }
    }

    function onRowSelect(row, isSelected) {

        if (isSelected) {
            setSelectProducts(prevArray =>
                [...prevArray, row._id]
            )
        } else {
            setSelectProducts(selectProducts.filter(item => item !== row._id))
        }
    }


    const handleChangeCountry = (i) => {
        let country = i.target.value
        if (country !== 0)
            setCountry(country)
        const filters = {
            skip: skip.toString(),
            limit: limit.toString(),
            country: country,
            status: 'obscure',
            creater: creater,
            branch
        };

        const queryParams = new URLSearchParams(filters).toString();
        dispatch(getAllLogisticProducts(queryParams))
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="სია" breadcrumbItem="გაურკვეველი"/>
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardBody>

                                    <Row>
                                        <Col>
                                            <label className="col-md-2 col-form-label">ქვეყანა</label>
                                            <select
                                                value={country}
                                                onChange={handleChangeCountry}
                                                className="form-control">
                                                <option value={0}>აირჩიეთ ქვეყანა</option>
                                                <option value={1}>ჩინეთი</option>
                                                <option value={2}>ამერიკა</option>
                                                <option value={3}>თურქეთი</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <ObjectColumns
                                onRowSelect={onRowSelect}
                                onSelectAll={onSelectAll}
                                logisticProducts={props.products.products}
                            />
                        </Col>
                    </Row>
                </div>
            </div>


        </React.Fragment>

    )
}


const mapStateToProps = state => {
    const {
        products,
        logistic
    } = state
    return {
        products,
        logistic
    }
};
export default withRouter(connect(mapStateToProps)(Waiting));
